import React from 'react';


const CardService = (props) => {


  return (
 
     <div className=" team col-lg-6" data-aos="fade-up" data-aos-delay="100">
     <div className="team-member d-flex align-items-start">
       <div className="pic"><img src={props.img} className="img-fluid" alt="" /></div>
       <div className="member-info">
         <h4>{props.tittle}</h4> 
         <p>{props.description}</p> 
       </div>
     </div>
   </div> 
 
  );
}

export default CardService;
