import React from 'react';
import { useTranslation } from 'react-i18next';
import CardService from './CardService';
import Accordion from './Accordion';

const Services = () => {
  const { t } = useTranslation();
  return (
    <section id="services" className="services section why-us">
 

      <div className="container section-title" data-aos="fade-up">
      
        <p>{t('services.description')}</p>
      </div>
      <div className="container">
        <div className="row gy-4">
          <CardService img={'../assets/img/services/condensador.jpg'} tittle={t('services.condenserReplacement')} description={t('services.condenserReplacementDescription')} />
          <CardService img={'../assets/img/services/airhandler.jpg'} tittle={t('services.airHandlerReplacement')} description={t('services.airHandlerReplacementDescription')} />
          <CardService img={'../assets/img/services/motorfan.jpg'} tittle={t('services.fanMotor')} description={t('services.fanMotorDescription')} />
          <CardService img={'../assets/img/services/capacitor.jpg'} tittle={t('services.capacitor')} description={t('services.capacitorDescription')} />
          <CardService img={'../assets/img/services/contacto.jpg'} tittle={t('services.contact')} description={t('services.contactDescription')} />
          <CardService img={'../assets/img/services/compresor.jpg'} tittle={t('services.compressor')} description={t('services.compressorDescription')} />
          <CardService img={'../assets/img/services/coil.jpg'} tittle={t('services.coilCleaning')} description={t('services.coilCleaningDescription')} />
          <CardService img={'../assets/img/services/split.jpg'} tittle={t('services.splitSystemEquipmentReplacement')} description={t('services.splitSystemEquipmentReplacementDescription')} />


        </div>

      </div>

    </section>
  );
}

export default Services;
