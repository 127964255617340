import React from 'react'; 
import Header from '../components/Header';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import Services from '../components/Services';
import Services1 from '../components/Services1';
import AboutUs from '../components/AboutUs';
import Contact from '../components/Contact';
import PriceCalculator from '../components/PriceCalculator';
import Gallery from '../components/ImageGallery'; 
import Footer from '../components/Footer'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
const HomePage = () => (
  <>
  <HelmetProvider>
  
    <Helmet>
      <title>Servicio de Reparación y Limpieza de Aire Acondicionado HVAC</title>
      <meta name="description" content="Manten tu aire acondicionado en perfectas condiciones con nuestro servicio de limpieza profesional. Reserva ahora y disfruta de aire fresco y limpio." />
      <meta property="og:title" content="Servicio de Limpieza de Aire Acondicionado HVAC" />
      <meta property="og:description" content="Manten tu aire acondicionado en perfectas condiciones con nuestro servicio de limpieza profesional." />
      <meta property="og:image" content="../assets/media/favicon.ico" />
      <meta property="og:url" content="https://www.allaboutducts305.com" />
      
    </Helmet>
   
    <Header />
    <Hero />   
    <AboutUs />

    <Services1 />
    <Services />
    <Contact /> 
     <Footer/>
    </HelmetProvider>
  </>
);

export default HomePage;
