import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 

const EstimationBox = () => {
  const { t } = useTranslation();
  const [bgImageCotiza, setBgImageCotiza] = useState('');

  useEffect(() => {
    // Cambia la imagen de fondo cada cierto tiempo
    const images = [
      "../assets/img/cotiza.webp",
      "../assets/img/cotiza1.webp" 
    ];
    let currentImageIndex = 0;

    const changeBackgroundImageCotiza = () => {
      setBgImageCotiza(images[currentImageIndex]);
      currentImageIndex = (currentImageIndex + 1) % images.length;
    };

    changeBackgroundImageCotiza();
    const intervalId = setInterval(changeBackgroundImageCotiza, 10000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    
      <div className="card recuadroCotiza">
       
          <p className="card-title">{t('estimations.title')} <br></br>
           </p>
          
           <img src={bgImageCotiza}/>
          <div className="d-flex justify-content-around divIconosContactos">
            <a href="#contact"  >
              <i className="bi bi-envelope"></i>  
            </a>
            <a href="tel:+13055425336"  >
              <i className="bi bi-telephone"></i> 
            </a>
            <a href="https://wa.me/+13055425336" >
              <i className="bi bi-whatsapp"></i>  
            </a>
          </div>
        
      </div>
    
  );
};

export default EstimationBox;
