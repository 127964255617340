import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Header1.css';


const Header1 = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    /**
     * Mobile nav toggle
     */
    const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');

    function mobileNavToogle() {
      document.querySelector('body').classList.toggle('mobile-nav-active');
      mobileNavToggleBtn.classList.toggle('bi-list');
      mobileNavToggleBtn.classList.toggle('bi-x');
    }
    mobileNavToggleBtn.addEventListener('click', mobileNavToogle);

    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll('#navmenu a').forEach(navmenu => {
      navmenu.addEventListener('click', () => {
        if (document.querySelector('.mobile-nav-active')) {
          mobileNavToogle();
        }
      });
    });

    /**
     * Toggle mobile nav dropdowns
     */
 

    // Cleanup function
    return () => {
      mobileNavToggleBtn.removeEventListener('click', mobileNavToogle);
      document.querySelectorAll('#navmenu a').forEach(navmenu => {
        navmenu.removeEventListener('click', () => {
          if (document.querySelector('.mobile-nav-active')) {
            mobileNavToogle();
          }
        });
      });
      document.querySelectorAll('.navmenu .toggle-dropdown').forEach(navmenu => {
        navmenu.removeEventListener('click', function (e) {
          if (document.querySelector('.mobile-nav-active')) {
            e.preventDefault();
            this.parentNode.classList.toggle('active');
            this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
            e.stopImmediatePropagation();
          }
        });
      });
    };
  }, []);

  return (
    <header id="header" className="header header1 d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">

        <a href="/" className="logo d-flex align-items-center me-auto">

          <h1 className="sitename1">
          
            <img src='../assets/img/logo.png' alt="AllAboutDucts" />
          </h1>
        </a>

        <nav id="navmenu" className="navmenu">
          <ul>
            <li><a href="./#hero" className="">{t('menu.home')}</a></li>
            <li><a href="./#about">{t('menu.about')}</a></li>
            <li><a href="./#services">{t('menu.services')}</a></li>
            <li><a href="./#contact">{t('menu.contact')}</a></li>
            <li><a> <span className="btn-languajes pull-right">
              <div className="language-selector">
                <span
                  onClick={() => changeLanguage('en')}
                  className={i18n.language === 'en' ? 'activeLanguaje' : ''}
                >
                   EN
                </span>
                |
                <span
                  onClick={() => changeLanguage('es')}
                  className={i18n.language === 'es' ? 'activeLanguaje' : ''}
                >
                 ES
                </span>
              </div>
            </span></a></li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>



      </div>
    </header>


  );
}

export default Header1;
