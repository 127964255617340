import React, { useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './ImageGallery.css';

const importAll = (r) => r.keys().map(r);

const imageFiles = importAll(require.context('../assets/media/gallery', false, /\.(png|jpe?g|svg)$/));
const videoFiles = importAll(require.context('../assets/media/gallery', false, /\.(mp4|webm)$/));

const ImageGallery = () => {
  const [filter, setFilter] = useState('all');

  const filteredMedia = () => {
    if (filter === 'images') return imageFiles;
    if (filter === 'videos') return videoFiles;
    return [...imageFiles, ...videoFiles];
  };

  return (
    <div className="container mt-4 gallery-content" >
      <div className="filter-buttons mb-4 ">
        <button onClick={() => setFilter('all')} className={`btn ${filter === 'all' ? 'btn-primary1' : 'btn-secondary'}`}>All</button>
        <button onClick={() => setFilter('images')} className={`btn ${filter === 'images' ? 'btn-primary1' : 'btn-secondary'}`}>Images</button>
        <button onClick={() => setFilter('videos')} className={`btn ${filter === 'videos' ? 'btn-primary1' : 'btn-secondary'}`}>Videos</button>
      </div>
      <div className="media-gallery">
        {filteredMedia().map((file, index) => (
          /\.(mp4|webm)$/.test(file) ? (
            <video key={index} controls className="gallery-item">
              <source src={file} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Zoom key={index}>
              <img src={file} alt={`Gallery Media ${index}`} className="gallery-item" />
            </Zoom>
          )
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;