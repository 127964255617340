import React from 'react';
import { Helmet, HelmetProvider }from 'react-helmet-async';
import ImageGallery from '../components/ImageGallery';
import Header1 from '../components/Header1';
import Hero from '../components/Hero';
const GalleryPage = () => (
  <>
  <HelmetProvider>
    <Helmet>
      <title>Gallery - Limpieza de Aire Acondicionado HVAC</title>
      <meta name="description" content="Lee nuestros artículos sobre limpieza y mantenimiento de sistemas HVAC." />
    </Helmet>
    <Header1></Header1>
    <ImageGallery />
  </HelmetProvider>
  </>
);

export default GalleryPage;
