
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from './Accordion';
import Carousel from './Carousel';

const Services1 = () => {
  const { t } = useTranslation();
  return (
    <>
    <section id="clients" className="clients section">

    <Carousel />
    
        </section>
    <section id="services" className="services section">
     
      <div className="container section-title" data-aos="fade-up">
        <h2>{t('services.title')}</h2>
        <p>{t('services.description')}</p>
      </div>
      <div className="container">

        <div className="row gy-4"> 
          <div className="col-xl-4 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item position-relative"> 
              <h4><a href="gallery" className="stretched-link">{t('services.ductsworks.title')}</a></h4>
              <p>{t('services.ductsworks.description')}</p>
            </div>
          </div>

          <div className="col-xl-4 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item position-relative"> 
              <h4><a href="gallery" className="stretched-link">{t('services.ductscleaning.title')}</a></h4>
              <p>{t('services.ductscleaning.description')}</p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item position-relative"> 
              <h4><a href="gallery" className="stretched-link">{t('services.hvacrepairs.title')}</a></h4>
              <p>{t('services.hvacrepairs.description')}</p>
            </div>
          </div>

        </div> 
      </div>

<br/>

      <section id="call-to-action" className="call-to-action section">

        <img src="assets/img/services/otros/chismera.jpg" alt="" />

        <div className="container">

          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-xl-9 text-center text-xl-start">
              <h3>{t('estimationService.title')}</h3>
              <p>{t('estimationService.description')}</p>
            </div>
            <div className="col-xl-3 cta-btn-container text-center">
              <a className="cta-btn align-middle" href="#">{t("llamanos")}</a>
            </div>
          </div>

        </div>

      </section>


    </section>
    </>
  );
};

export default Services1;
