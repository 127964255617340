import React, { useEffect, useState } from 'react';
import './Hero.css';
import { useTranslation } from 'react-i18next';
import EstimationBox from './EstimationBox';
const Hero = () => {
  const { t } = useTranslation();
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    // Cambia la imagen de fondo cada cierto tiempo
    const images = [
       'url("../assets/img/a.jpeg")',
      'url("../assets/img/b.jpeg")',
      'url("../assets/img/a.jpg")',
      'url("../assets/img/hero3_bg.jpg")',
      'url("../assets/img/hero1_bg.jpg")',
      'url("../assets/img/hero2_bg.jpg")'
    ];
    let currentImageIndex = 0;

    const changeBackgroundImage = () => {
      setBgImage(images[currentImageIndex]);
      currentImageIndex = (currentImageIndex + 1) % images.length;
    };

    changeBackgroundImage();
    const intervalId = setInterval(changeBackgroundImage, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section id="hero" className="hero section" style={{ backgroundImage: "linear-gradient(to right, rgba(39, 70, 133, 0.8) 0%, rgba(61, 179, 197, 0.8) 100%) ," + bgImage }}>
      <div className="container">
        <div className="row gy-4">
        <div className="col-lg-12 logoBannerMobil">
        <img  src="../assets/img/logoM.png" />
        </div>
             
          <div className="col-lg-9 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="zoom-out">

            <h1>{t('banner.title')}</h1>
            <p>{t('banner.description')}</p>

            <div className="d-flex">
              <a href="#contact" className="btn-get-started">{t('leave_message')}</a>
              <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
            </div>
            <div className="d-flex divLeaveMessageMobil">
            {t('leave_message')}
            </div>
            <div className="d-flex   divIconosContactosMobil">
              <a href="#contact"  >
                <i className="bi bi-envelope"></i>
              </a>
              <a href="tel:+13055425336"  >
                <i className="bi bi-telephone"></i>
              </a>
              <a href="https://wa.me/+13055425336" target='_blank'>
                <i className="bi bi-whatsapp"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="200">
            <EstimationBox />
          </div>
        </div>
      </div>

    </section>
  );
};

export default Hero;
