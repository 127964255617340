import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    axios.post('https://allaboutducts305.com/m.php', formData)
      .then((response) => {
        setLoading(false);
        setSuccessMessage('Your message has been sent. Thank you!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage('There was an error sending your message. Please try again.');
      });
  };

  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>{t('menu.contact')}</h2>
        <p>{t('contact.description')}</p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-5">
            <div className="info-wrap">
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>{t("contact.address")}</h3>
                  <p>Miami, Florida</p>
                </div>
              </div>
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                <a href="tel:+13055425336">
                  <i className="bi bi-telephone flex-shrink-0"></i>
                </a>
                <div>
                  <h3>{t("contact.callus")}</h3>
                  <p><a href="tel:+13055425336">+1 (305) 542-5336</a></p>
                </div>
              </div>
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>{t("contact.emailus")}</h3>
                  <p>admin@allaboutducts305.com</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <h4>{t("contact.followus")}</h4>
                <div className="d-flex">
                  <div className="info-item">
                    <a href="https://www.tiktok.com/@allaboutducts"><i className="info-item bi bi-tiktok flex-shrink-0"></i></a>
                  </div>
                  <div className="info-item">
                    <a href=""><i className="bi bi-facebook flex-shrink-0"></i></a>
                  </div>
                  <div className="info-item">
                    <a href="https://www.instagram.com/allaboutducts"><i className="bi bi-instagram flex-shrink-0"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <form onSubmit={handleSubmit} className="php-email-form" data-aos="fade-up" data-aos-delay="200">
              <div className="row gy-4">
                <div className="col-md-6">
                  <label htmlFor="name-field" className="pb-2">Your Name</label>
                  <input type="text" name="name" id="name-field" className="form-control" required value={formData.name} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email-field" className="pb-2">Your Email</label>
                  <input type="email" className="form-control" name="email" id="email-field" required value={formData.email} onChange={handleChange} />
                </div>
                <div className="col-md-12">
                  <label htmlFor="subject-field" className="pb-2">Subject</label>
                  <input type="text" className="form-control" name="subject" id="subject-field" required value={formData.subject} onChange={handleChange} />
                </div>
                <div className="col-md-12">
                  <label htmlFor="message-field" className="pb-2">Message</label>
                  <textarea className="form-control" name="message" rows="5" id="message-field" required value={formData.message} onChange={handleChange}></textarea>
                </div>
                <div className="col-md-12 text-center">
                  {loading && <div className="loading">Loading</div>}
                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                  {successMessage && <div className="sent-message">{t("contact.messageOk")}</div>}
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
