import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurvedSection } from './CurvedSection';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="footer" className="footer">

      <div className="footer-newsletter">
        <div className="container">
          <div className="row">
            <div  className="col-xl-2 col-md-2 divPerson" data-aos="fade-up" data-aos-delay="100">
              <img src="../assets/img/test.png"  />
            </div>
            <div className="col-xl-8 col-md-8">
              <h4>Alfredo Cordoves</h4>
              <p>{t("hvacChief.description")}</p>
             
            </div>
            <div className="col-xl-2 col-md-2">
              </div>
          </div>
        </div>
      </div>



      <div className="container copyright text-center mt-4">
        <p>© <span>Copyright</span> <strong className="px-1">ALLABOUTDUCTS</strong> <span>All Rights Reserved</span></p>
        <div className="credits">
          Designed by <a href="https://carloshilario.com/">CHGARCIA</a>
        </div>
      </div>

    </footer>
  );
}

export default Footer;
