import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurvedSection } from './CurvedSection';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="about section">

      <div className="container section-title" data-aos="fade-up">
        <h2>{t('aboutUs.title')}</h2>
        <p>{ t('aboutUs.additionalDescription') }</p>

      </div>

      <div className="container">

        <div className="row gy-4">

         

          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="200">
            <p>{t('aboutUs.description')}</p>
            <div className="row">
          
              <div className="col-lg-6" >
                <ul>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item1')}</span></li>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item2')}</span></li>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item3')}</span></li>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item4')}</span></li>
                </ul>
              </div>
              <div className="col-lg-6" >
                <ul>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item5')}</span></li>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item6')}</span></li>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item7')}</span></li>
                  <li><i className="bi bi-check2-circle"></i> <span>{t('aboutUs.item8')}</span></li>
                </ul>
              </div>
            </div>


          </div>
          
        </div>
        
      </div>

    </section>
  );
}

export default AboutUs;
