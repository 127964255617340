import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { useTranslation } from 'react-i18next';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default () =>  {
    const { t } = useTranslation();
    const slides = [ 
        { src: "../assets/img/services/otros/chismera.jpg", alt: "Condensador", text: t("chismera") },
        { src: "../assets/img/services/otros/difusores lineales.jpg", alt: "Condensador", text: t("disfusores") },
        { src: "../assets/img/services/otros/rejasregulares.jpg", alt: "Condensador", text: t("rejas") },
        { src: "../assets/img/services/otros/fiberglass.jpg", alt: "Condensador", text: t("fiberglass") },
        { src: "../assets/img/services/otros/doublewall.jpg", alt: "Condensador", text: t("double") },
        { src: "../assets/img/services/otros/chismera1.jpg", alt: "Condensador", text: t("chismera") },
        { src: "../assets/img/services/otros/doublewall2.jpg", alt: "Condensador", text: t("disfusores") },
        { src: "../assets/img/services/otros/rejasregulares1.jpg", alt: "Condensador", text: t("rejas") },
        { src: "../assets/img/services/otros/fiberglass2.jpg", alt: "Condensador", text: t("fiberglass") },
        { src: "../assets/img/services/otros/doublewall3.jpg", alt: "Condensador", text: t("double") },
      ];
    return (
        <>
          <Swiper
         
            slidesPerView={5}
            spaceBetween={30} 
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 10 },
                480: { slidesPerView: 2, spaceBetween: 20 },
                640: { slidesPerView: 3, spaceBetween: 30 },
                992: { slidesPerView: 4, spaceBetween: 40 },
                1200: { slidesPerView: 5, spaceBetween: 50 },
              }}
            className="mySwiper"
          >
              {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">
              <Zoom>
                <img src={slide.src} alt={slide.alt} loading="lazy" />
              </Zoom>
              <div className="overlay">
                <div className="text SwiperSlideText">{slide.text}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
          </Swiper>
        </>
      );
}